<template>
  <div
    v-if="result"
    class="result-container"
    :style="{
      backgroundImage:
        'url(' + (faceResult == '01' ? img1 : faceResult == '02' ? img2 : img3) + ')',
    }"
  >
    <div class="result-main">
      <div class="p-t44 rem46 lh31">
        <span v-if="faceResult === '01'">处理中</span>
        <span v-if="faceResult === '02'">恭喜你</span>
        <span v-if="faceResult === '03'" class="c-FE4D4DFF">很抱歉</span>
      </div>
      <div class="rem30 lh31 c-8E929B">
        <span v-if="faceResult === '01'">请等待处理结果</span>
        <span v-if="faceResult === '02'">处理成功</span>
        <span v-if="faceResult === '03'">处理失败</span>
      </div>
      <div class="result-btn m5" v-if="faceResult == '01' || faceResult == '03'">
        <van-button round block color="#4D7BFE" class="btn" @click="toIndex">
          <span class="rem34">返回首页</span>
        </van-button>
        <van-button
          round
          block
          plain
          color="#4D7BFE"
          class="btn"
          @click="queryResult"
          v-if="faceResult === '01'"
        >
          <span class="rem34">查询结果</span>
        </van-button>
      </div>
      <div class="result-btn m5" v-if="faceResult === '02'">
        <van-button round block plain color="#4D7BFE" class="btn" @click="toBorrow">
          <span class="rem34">去融资</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { Button } from 'vant'
import { loanApplyCheckApi } from '@/apis/borrow'

Vue.use(Button)
export default {
  data() {
    return {
      img1: require('@/assets/images/payment/result1.png'),
      img2: require('@/assets/images/payment/result2.png'),
      img3: require('@/assets/images/payment/result3.png'),
      result: false,
      faceResult: '00',
    }
  },
  created() {
    this.loanApplyCheck()
  },
  computed: {
    ...mapState({
      loanNo: (state) => state.borrow.loanNo,
      faceToken: (state) => state.borrow.faceToken,
      productCode: (state) => state.common.productCode,
      applySeq: (state) => state.borrow.applySeq,
    }),
  },
  methods: {
    // 刷脸后 用于后端跑校验规则
    loanApplyCheck() {
      loanApplyCheckApi(this.loanNo)
        .then((res) => {
          if (res.code === 200) {
            this.faceResult = '02'
          } else {
            this.faceResult = '01'
          }
          this.result = true
        })
        .catch((err) => {
          this.faceResult = '03'
          this.result = true
        })
    },
    queryResult() {
      this.loanApplyCheck()
    },
    toIndex() {
      this.$router.push({ path: this.$store.state.common.defaultIndex })
    },
    toBorrow() {
      this.$router.push('/confirm-borrow')
    },
  },
}
</script>

<style lang="less" scoped>
.result-container {
  padding: 0 0.42rem /* 21/50 */;
  background-size: 100% 8.4rem /* 420/50 */;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  .result-body {
    background-color: #fff;
    margin-top: 0.72rem /* 36/50 */;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 0.38rem /* 19/50 */ 0.3rem /* 15/50 */;
    border-radius: 4px;
    .icon-qq {
      margin-top: 0.18rem /* 9/50 */;
      display: inline-block;
      width: 0.2rem /* 10/50 */;
      height: 0.2rem /* 10/50 */;
      border-radius: 50%;
      border: 0.04rem /* 2/50 */ solid #4d7bfe;
      box-sizing: border-box;
      margin-right: 0.1rem /* 5/50 */;
    }
  }

  .result-btn {
    margin-top: 0.82rem /* 41/50 */;
  }
  .btn {
    margin-top: 0.48rem /* 24/50 */;
  }
}
</style>
